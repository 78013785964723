<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
            <em>当前位置：</em>
            <a href="javascript:;">课程大纲</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem ci-full">
            </div>
          </div>
          <div class="btnBox">
            <el-button class="bgc-bv" @click="openoutlineDialog(topLeveloutline)">新增顶级大纲</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table :data="outlineTree" ref="refTable" @row-dblclick="expandChange" :header-cell-style="tableHeader" height="100%" size="small" style="width: 100%"
                      row-key="outlineId">
              <el-table-column prop="outlineName" label="大纲名称" align="left" width="400px"></el-table-column>
              <el-table-column prop="outlineCode" label="大纲码值" align="left" width="100px"></el-table-column>
              <el-table-column label="显示/隐藏" align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.usable" active-color="#ff4949" inactive-color="#c0ccda" @change="outlineChangehidden(scope.row)"/>
                  <span style="margin-left: 5px">{{ !scope.row.usable ? '隐藏' : '显示' }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="outlineMust" label="必修标识" align="center" :formatter="isUsable" width="100px"></el-table-column>
              <el-table-column prop="outlinePeriod" label="大纲课时" align="left" width="100px"></el-table-column>
              <el-table-column prop="delete" label="删除标识" align="center" :formatter="isDelete" width="100px"></el-table-column>
              <el-table-column prop="outlineRemark" label="备注" align="left"></el-table-column>
              <el-table-column prop="outlineOrder" label="排序" align="left" width="100px"></el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <div slot-scope="scope" class="">
                  <el-button size="mini" type="text" @click="openoutlineEditDialog(scope.row)">编辑</el-button>
                  <el-button size="mini" type="text" @click="outlineDel(scope.row)">删除</el-button>
                  <el-button size="mini" type="text" @click="openoutlineDialog(scope.row)" v-if="scope.row.outlineLevel != '3'">新增子大纲</el-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="outlineDialogTitle" :visible.sync="outlineDialogVisible" width="30%" center>
      <el-form ref="outlineDialogFromRef" :model="outlineDialogFrom" :rules="outlineDialogFromRules" label-width="120px">
        <el-form-item label="上级大纲名称" prop="parentName" size hidden>
          <el-input v-model="outlineDialogFrom.parentName" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="上级大纲ID" prop="parentId" size hidden>
          <el-input v-model="outlineDialogFrom.parentId" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="上级大纲码值" prop="parentCode" size hidden>
          <el-input v-model="outlineDialogFrom.parentCode" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="大纲级别" prop="outlineLevel" hidden>
          <el-input v-model="outlineDialogFrom.outlineLevel" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="显示/隐藏" prop="usable">
          <el-switch v-model="outlineDialogFrom.usable">
          </el-switch>
          <span style="margin-left: 5px">{{ outlineDialogFrom.usable?'显示':'隐藏'}}</span>
        </el-form-item>
        <el-form-item label="必修/选修" prop="outlineMust">
          <el-switch v-model="outlineDialogFrom.outlineMust"/>
          <span style="margin-left: 5px">{{ outlineDialogFrom.outlineMust?'必修':'选修'}}</span>
        </el-form-item>
        <el-form-item label="大纲码值" prop="outlineCode">
          <el-input v-model="outlineDialogFrom.outlineCode" :disabled="outlineDialogFrom.isEdit" minlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')">
            <template slot="prepend">{{ outlineDialogFrom.parentCode }}</template>
          </el-input>
          <span style="font-size: 5px">数字范围为01~99，请自行观察码值规律</span>
        </el-form-item>
        <el-form-item label="大纲名称" prop="outlineName">
          <el-input v-model="outlineDialogFrom.outlineName" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="大纲排序" prop="outlineOrder">
          <el-input v-model.number="outlineDialogFrom.outlineOrder" maxlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          <span style="font-size: 5px">数字范围为1~99，数字越小越靠前。数字相同，先添加的排序靠前</span>
        </el-form-item>
        <el-form-item label="大纲课时" prop="outlinePeriod">
          <el-input v-model.number="outlineDialogFrom.outlinePeriod" maxlength="5" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="outlineRemark">
          <el-input v-model="outlineDialogFrom.outlineRemark" maxlength="100" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="outlineDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="outlineAdd()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="outlineEditDialogTitle" :visible.sync="outlineEditDialogVisible" width="30%" center>
      <el-form ref="outlineEditDialogFromRef" :model="outlineEditDialogFrom" :rules="outlineEditDialogFromRules" label-width="120px">
        <el-form-item label="大纲ID" prop="outlineId" hidden>
          <el-input v-model="outlineEditDialogFrom.outlineId" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="大纲级别" prop="outlineLevel" hidden>
          <el-input v-model="outlineEditDialogFrom.outlineLevel" show-word-limit disabled></el-input>
        </el-form-item>
        <el-form-item label="显示/隐藏" prop="usable">
          <el-switch v-model="outlineEditDialogFrom.usable"/>
          <span style="margin-left: 5px">{{ outlineEditDialogFrom.usable?'显示':'隐藏'}}</span>
        </el-form-item>
        <el-form-item label="必修/选修" prop="outlineMust">
          <el-switch v-model="outlineEditDialogFrom.outlineMust"/>
          <span style="margin-left: 5px">{{ outlineEditDialogFrom.outlineMust?'必修':'选修'}}</span>
        </el-form-item>
        <el-form-item label="大纲码值" prop="outlineCode">
          <el-input v-model="outlineEditDialogFrom.outlineCode" minlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')">
            <template slot="prepend">{{ outlineEditDialogFrom.parentCode }}</template>
          </el-input>
          <span style="font-size: 5px">数字范围为01~99，请自行观察码值规律</span>
        </el-form-item>
        <el-form-item label="大纲名称" prop="outlineName">
          <el-input v-model="outlineEditDialogFrom.outlineName" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="大纲排序" prop="outlineOrder">
          <el-input v-model.number="outlineEditDialogFrom.outlineOrder" maxlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
          <span style="font-size: 5px">数字范围为1~99，数字越小越靠前。数字相同，先添加的排序靠前</span>
        </el-form-item>
        <el-form-item label="大纲课时" prop="outlinePeriod">
          <el-input v-model.number="outlineEditDialogFrom.outlinePeriod" maxlength="5" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="outlineRemark">
          <el-input v-model="outlineEditDialogFrom.outlineRemark" maxlength="100" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="outlineEditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="outlineEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";

export default {
  name: "mini/outline",
  components: {
  },
  mixins: [List],
  data() {
    return {
      isClearable: true,      // 可清空（可选）
      isAccordion: true,      // 可收起（可选）
      props: {
        value: 'outlineId',
        label: 'outlineName',
        children: 'children',
        // disabled:true
      },
      searchoutlineId: "",
      outlineDialogVisible: false,
      outlineDialogTitle: "新增大纲",
      outlineEditDialogVisible: false,
      outlineEditDialogTitle: "编辑大纲",
      outlineDialogFrom: {
        parentId: "", //父级ID
        parentName: "", //父级大纲名称
        parentCode: "", //父级大纲码值
        outlineName: "", //大纲名称
        outlineLevel: "", //大纲级别
        outlineOrder: "", //大纲排序
        outlineCode: "", //大纲码值
        outlineRemark: "", //备注
        outlinePeriod: "",
        outlineMust: true,
        usable: false,
      },
      outlineDialogFromRules: {
        outlineName: [{required: true, message: "请输入大纲名称", trigger: "blur",},],
        outlineOrder: [
          {required: true, message: "请输入大纲排序", trigger: "blur",},
          {type: "number", message: "大纲排序必须为数字值"},
          {validator: this.validatePass, trigger: "blur"},
        ],
        outlineCode: [
          {required: true, message: "请输入大纲码值", trigger: "blur",},
          {validator: this.validatePass, trigger: "blur"},
        ],
      },
      outlineEditDialogFrom: {
        outlineId: "",
        outlineName: "", //大纲名称
        outlineLevel: "", //大纲级别
        outlineOrder: "", //大纲排序
        outlineCode: "", //大纲码值
        outlineRemark: "", //备注
        outlinePeriod: "",
        outlineMust: true,
        usable: false,
      },
      outlineEditDialogFromRules: {
        outlineName: [{required: true, message: "请输入大纲名称", trigger: "blur",},],
        outlineOrder: [
          {required: true, message: "请输入大纲排序", trigger: "blur",},
          {type: "number", message: "大纲排序必须为数字值"},
          {validator: this.validatePass, trigger: "blur"},
        ],
        outlineCode: [
          {required: true, message: "请输入大纲码值", trigger: "blur",},
          {validator: this.validatePass, trigger: "blur"},
        ],
      },
      outlineTree: [],
      outlineOptionData: [],
      topLeveloutline: {
        outlineId: 0,
        outlineName: '无--顶级大纲',
        outlineCode: '',
        outlineLevel: 0
      }
    };
  },
  watch: {},
  created() {
  },
  computed: {

  },
  methods: {
    normalizer(node) {
      console.log(node)
      //去掉children=null的属性
      if (node.children == null || node.children == 'null') {
        delete node.children;
      }
    },
    isDelete(row, column, cellValue, index) {
      return cellValue ? "已删除" : "未删除"
    },
    isUsable(row, column, cellValue, index) {
      return cellValue ? "必修" : "选修"
    },
    expandChange(row, index, e) {
      this.$refs.refTable.toggleRowExpansion(row)
    },
    validatePass(rule, value, callback) {
      if (parseInt(value) > 99) {
        callback(new Error("最大值应在99以内"));
      } else if (parseInt(value) < 1) {
        callback(new Error("最小值应大于等于1"));
      } else {
        callback();
      }
    },
    //获取大纲
    getData() {
      let params = {
        disabledLevels: "1,2"
      }
      this.$post("/miniapp/outline/tree", params, 3000, true, 2)
          .then((res) => {
            if (res.status == 0) {
              console.log('res.data', res.data);
              this.outlineTree = res.data;
              this.outlineOptionData = res.data;
              return;
            }
            this.$message({
              type: "error",
              message: res.message ? res.message : "操作失败",
            });
          })
          .catch(() => {
            return;
          });
    },
    //添加大纲接口
    outlineAdd() {
      this.$refs["outlineDialogFromRef"].validate((valid) => {
        if (valid) {
          const params = {
            parentId: this.outlineDialogFrom.parentId,
            outlineName: this.outlineDialogFrom.outlineName,
            outlineCode: this.outlineDialogFrom.parentCode + this.outlineDialogFrom.outlineCode,
            outlineLevel: this.outlineDialogFrom.outlineLevel,
            outlineOrder: this.outlineDialogFrom.outlineOrder,
            outlineRemark: this.outlineDialogFrom.outlineRemark,
            outlineMust: this.outlineDialogFrom.outlineMust,
            outlinePeriod: this.outlineDialogFrom.outlinePeriod,
            usable: this.outlineDialogFrom.usable,
          };
          this.$post(
              "/miniapp/outline/insert",
              params,
              3000,
              true,
              2
          ).then((res) => {
            this.$message({
              type: res.status == "0" ? "success" : "error",
              message: res.message ? res.message : "操作失败",
            });
            if (res.status == 0) {
              this.$refs[
                  "outlineDialogFromRef"
                  ].resetFields();
              this.outlineDialogVisible = false;
              this.getData();
            }
          }).catch(() => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 添加大纲弹窗
    openoutlineDialog(row) {
      if (this.$refs["outlineDialogFromRef"] !== undefined) {
        this.$refs["outlineDialogFromRef"].resetFields();
      }
      this.$post(
          "/miniapp/outline/initialize",
          {parentId: row.outlineId},
          3000,
          true,
          2
      ).then((res) => {
        console.log(res.data)
        if (res.status === "0") {
          this.outlineDialogVisible = true;
          this.outlineDialogFrom = res.data;
          this.categoryDialogTitle = "新增大纲";
        } else {
          this.$message({
            type: "error",
            message: res.message ? res.message : "操作失败",
          });
        }
      }).catch(() => {
        return;
      });
    },
    // 编辑大纲弹窗
    openoutlineEditDialog(row) {
      if (this.$refs["outlineEditDialogFromRef"] !== undefined) {
        this.$refs["outlineEditDialogFromRef"].resetFields();
      }
      this.outlineEditDialogVisible = true;
      this.outlineEditDialogFrom.outlineId = row.outlineId;
      this.outlineEditDialogFrom.outlineName = row.outlineName;
      this.outlineEditDialogFrom.outlineCode = row.outlineCode.substring(row.outlineCode.length - 2);//只展示后两位，目的是不让修改前几位
      this.outlineEditDialogFrom.outlineLevel = parseInt(row.outlineLevel);
      this.outlineEditDialogFrom.outlineOrder = row.outlineOrder;
      this.outlineEditDialogFrom.outlineRemark = row.outlineRemark;
      this.outlineEditDialogFrom.outlineMust = row.outlineMust;
      this.outlineEditDialogFrom.outlinePeriod = row.outlinePeriod;
      this.outlineEditDialogFrom.usable = row.usable;
      this.outlineEditDialogFrom.parentCode = row.parentCode;
      this.outlineEditDialogTitle = "编辑大纲";
    },
    // 编辑大纲
    outlineEdit() {
      this.$refs["outlineEditDialogFromRef"].validate((valid) => {
        if (valid) {
          // 将父类code和本次大纲code拼接为最终需要的code，目的是不让修改前几位
          this.outlineEditDialogFrom.outlineCode = this.outlineEditDialogFrom.parentCode + this.outlineEditDialogFrom.outlineCode
          this.$post(
              "/miniapp/outline/modify",
              this.outlineEditDialogFrom,
              3000,
              true,
              2
          ).then((res) => {
            this.$message({
              type: res.status == "0" ? "success" : "error",
              message: res.message ? res.message : "操作失败",
            });
            if (res.status == 0) {
              this.$refs[
                  "outlineEditDialogFromRef"
                  ].resetFields();
              this.outlineEditDialogVisible = false;
              this.getData();
            }
          }).catch(() => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除
    outlineDel(info) {
      if (info.delete == true) {
        this.$message({type: "error", message: "已删除，无法继续操作",});
        return;
      }
      if (info.children && info.children.length > 0) {
        this.$message({type: "error", message: "存在子大纲，无法删除！",});
        return;
      }
      this.$confirm("删除后，将不可恢复", "确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post(
            "/miniapp/outline/delete",
            info,
            3000,
            true,
            2
        ).then((ret) => {
          ret.status === "0"
              ? this.getData()
              : this.$message.error(ret.message || "刪除失败！");
        });
      }).catch(() => {
        return;
      });
    },
    //修改大纲显示状态
    outlineChangehidden(info) {
      const params = {
        outlineId: info.outlineId,
        usable: info.usable,
      };
      this.$post(
          "/miniapp/outline/changeHidden",
          params,
          3000,
          true,
          2
      ).then((res) => {
        this.$message({
          type: res.status == 0 ? "success" : "error",
          message: res.message ? res.message : "操作失败",
        });
      }).catch(() => {
        return;
      });
    },
  },
};
</script>
<style lang="less">

</style>
